import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import logo from '../images/IM_Logo.png';

import PasswordRequirements from '../components/popup/PasswordRequirements';
import { useFormFields } from "../libs/hooks";
import { secureFetch } from '../libs/utils'

import authService from "../services/auth";

function Register() {
  const [errorMessage, setErrorMessage] = useState("");
  const [fields, handleFieldChange] = useFormFields({
    name: "",
    email: "",
    password: "",
    passwordRepeat: "",
    agree: false
  });

  const history = useHistory();

  const handleRegister = async (e) => {
    e.preventDefault();

    await secureFetch(
      () => authService.register(fields),
      () => {
        authService.setEmailAddress(fields.email);
        history.push("/confirmEmail");
      },
      setErrorMessage,
      'Hiba a regisztráció során'
    );
  }

  const isRegisterDisabled = () => {
    return !fields.agree ||
      fields.name === "" ||
      fields.email === "" ||
      fields.password === "" ||
      fields.passwordRepeat === "";
  }

  return (
    <section className="register">
      <div className="container">
      <form className="form">
        <div className="form__logo">
          <img src={logo} className="logo" alt="logo" />
        </div>
        {errorMessage && (
          <div className="form__row">
            <span className="error">{errorMessage}</span>
          </div>
        )}
        <div className="form__row">
          <input
            id="name"
            placeholder="Név"
            icon="user"
            iconPosition="left"
            onChange={handleFieldChange}
          />
        </div>
        <div className="form__row">
          <input
            id="email"
            placeholder="E-mail cím"
            icon="mail"
            iconPosition="left"
            onChange={handleFieldChange}
            required
          />
        </div>
        <div className="form__row">
          <PasswordRequirements/>
          <input
            id="password"
            placeholder="Jelszó"
            type="password"
            icon="lock"
            iconPosition="left"
            onChange={handleFieldChange}
            data-tip
            data-for="password"
          />
        </div>
        <div className="form__row">
          <input
            id="passwordRepeat"
            placeholder="Jelszó ismét"
            type="password"
            icon="lock"
            iconPosition="left"
            onChange={handleFieldChange}
          />
        </div>
        <div className="form__row check">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="agree"
              name="privacy-policy"
              className="custom-control-input"
              required
              onChange={handleFieldChange}
            />
            <label for="agree" className="custom-control-label">
              Elfogadom a
              { ' ' }
              <a
                href="/files/felhasznalasi_feltetelek.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Felhasználási feltételeket,
              </a>
              { ' ' }
              <a
                href="/files/adatkezelesi_tajekoztato.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Adatkezelési tájékoztatót
              </a>
              { ' ' }
              és az
              { ' ' }
              <a
                href="/files/adatfeldolgozoi_nyilatkozat.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Adatfeldolgozási tájékoztatót.
              </a>
            </label>
          </div>
        </div>
        <div className="form__row">
          <button
            className="btn"
            onClick={handleRegister}
            disabled={isRegisterDisabled()}
          >
            Regisztráció
          </button>
        </div>
      </form>
      </div>
    </section>
  );
}

export default Register;
