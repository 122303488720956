import React, { useState } from 'react';
import logo from '../images/logo-black.svg';

import ConfirmEmailForm from './sections/ConfirmEmailForm';
import ConfirmEmailSuccess from './sections/ConfirmEmailSuccess';

import { useFormFields, useDidMountEffect } from "../libs/hooks";
import { secureFetch } from '../libs/utils';
import authService from "../services/auth";

function ConfirmEmail(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: props.match.params.email !== undefined
    ? decodeURIComponent(props.match.params.email)
    : authService.getEmailAddress(),
    code: props.match.params.code !== undefined
      ? decodeURIComponent(props.match.params.code)
      : null,
  });

  const handleConfirm = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    await secureFetch(
      () => authService.confirmEmail(fields),
      () => setConfirmed(true),
      setErrorMessage,
      'Nem sikerült megerősíteni az email címet'
    );
  };

  const tryAutoSubmit = async () => {
    const { email, code } = fields;
    if (email && code) {
      await submit();
    }
  };

  useDidMountEffect(() => tryAutoSubmit(), []);

  return (
    <section className="register">
      <div className="container">
        <form className="form">
          <div className="form__logo">
            <img src={logo} className="logo" alt="logo" />
          </div>
          {confirmed
            ? <ConfirmEmailSuccess/>
            : <ConfirmEmailForm
                errorMessage={errorMessage}
                fields={fields}
                handleFieldChange={handleFieldChange}
                handleConfirm={handleConfirm}
              />
          }
        </form>
      </div>
    </section>
  );
}

export default ConfirmEmail;
