import React from 'react'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

const RangeSlider = (props)  => {
  const { onChange, value, minValue = 0, maxValue = 100 } = props;

  return (
    <div className='slider'>
      <Slider
        min={minValue}
        max={maxValue}
        value={value}
        onChange={onChange}
      />
      <div className='value'>{value} nap után</div>
    </div>
  );
}

export default RangeSlider;
