import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import logo from '../images/IM_Logo.png';

import { useCallback } from "react";
import { useFormFields } from "../libs/hooks";
import { secureFetch } from '../libs/utils'

import authService from "../services/auth";

function Landing() {

  const [errorMessage, setErrorMessage] = useState("");
  const [fields, handleFieldChange] = useFormFields({
    userName: "",
    password: "",
    agree: false,
  });

  const history = useHistory();

  const checkLoggedInUser = useCallback(() => {
    const currentUser = authService.getCurrentUser();
    const sixHoursInMs = 6 * 60 * 60 * 1000;
    if(
      currentUser &&
      currentUser.tokenExpires > new Date().getTime() + sixHoursInMs) {
      history.push("/dashboard");
    } else {
      authService.logout();
    }
  }, [history]);

  useEffect(() => checkLoggedInUser(), [checkLoggedInUser]);

  const handleLogin = async (e) => {
    e.preventDefault();

    await secureFetch(
      () => authService.login(fields),
      () => history.push("/dashboard"),
      setErrorMessage,
      'Hiba a bejelentkezés során'
    );
  };

  const isLoginDisabled = () => {
    return !fields.agree || fields.userName === "" || fields.password === "";
  }

  return (
    <section className="login">
      <div className="container">
        <form className="form">
          <div className="form__logo">
            <img src={logo} className="logo" alt="logo" />
          </div>
          {errorMessage && (
            <div className="form__row">
              <span className="error">{errorMessage}</span>
            </div>
          )}
          <div className="form__row">
            <input
              id="userName"
              placeholder="E-mail cím"
              onChange={handleFieldChange}
              required
              value={fields.userName}
            />
          </div>
          <div className="form__row">
            <input
              id="password"
              placeholder="Jelszó"
              type="password"
              onChange={handleFieldChange}
              value={fields.password}
            />
          </div>
          <div className="form__row check">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                id="agree"
                name="privacy-policy"
                className="custom-control-input"
                required
                onChange={handleFieldChange}
              />
              <label for="agree" className="custom-control-label">
                Elfogadom a
                { ' ' }
                <a
                  href="/files/felhasznalasi_feltetelek.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Felhasználási feltételeket,
                </a>
                { ' ' }
                <a
                  href="/files/adatkezelesi_tajekoztato.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Adatkezelési tájékoztatót
                </a>
                { ' ' }
                és az
                { ' ' }
                <a
                  href="/files/adatfeldolgozoi_nyilatkozat.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Adatfeldolgozási tájékoztatót.
                </a>
              </label>
            </div>
          </div>
          <div className="form__row">
            <button
              className="btn"
              onClick={handleLogin}
              disabled={isLoginDisabled()}
            >
              Bejelentkezés
            </button>
          </div>
          <div className="form__row">
            <Link to="/forgot">
              Elfelejtetted a jelszavadat?
            </Link>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Landing;
