import { Modal } from 'react-bootstrap';

const ErrorModal = (props) => {
  const { errorMessage, setErrorMessage } = props;

  return (
    <Modal show={errorMessage !== ""} centered>
      <Modal.Body>
          {errorMessage}
      </Modal.Body>
      <Modal.Footer>
          <button className="btn" onClick={() => setErrorMessage("")}>
          Rendben
          </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ErrorModal;
