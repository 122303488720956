import React, { useState } from 'react';
import { Link } from "react-router-dom";
import logo from '../images/logo-black.svg';

import { useFormFields } from "../libs/hooks";
import { secureFetch } from '../libs/utils'

import authService from "../services/auth";

function Forgot() {
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: ""
  });

  const handleReset = async (e) => {
    e.preventDefault();

    await secureFetch(
      () => authService.forgotPassword(fields),
      () => setSuccess(true),
      setErrorMessage,
      'Nem sikerült elküldeni a kódot a jelszó visszaállításához'
    );
  };

  const renderResetForm = () => {
    return (
      <form className="form">
        <div className="form__logo">
          <img src={logo} className="logo" alt="logo" />
        </div>
        {errorMessage && (
          <div className="form__row">
            <span className="error">{errorMessage}</span>
          </div>
        )}
        <div className="form__row">
          <input
            id="email"
            placeholder="E-mail cím"
            required
            onChange={handleFieldChange}
            value={fields.email}
          />
        </div>
        <div className="form__row">
          <button className="btn" onClick={handleReset}>
            Küldés
          </button>
        </div>
        <div className="form__row">
          Ha még nem regisztráltál,
          {' '}
          <Link to="/register">
            itt
          </Link>
          {' '}
          megteheted.
        </div>
      </form>
    );
  }

  const renderSuccessMessage = () => {
    return (
      <form className="form">
        <div className="form__logo">
          <img src={logo} className="logo" alt="logo" />
        </div>
        <div className="form__row">
          Amennyiben a regisztrációdhoz használt email címedet adtad meg,
          a jelszóvisszaállításhoz szükséges kódot erre a címre elküldtük. 
        </div>
        <div className="form__row">
          <Link to="/">
            <button className="btn">
              Vissza
            </button>
          </Link>
        </div>
      </form>
    );
  }

  return (
    <section className="forgot">
      <div className="container">
        {success ? renderSuccessMessage() : renderResetForm()}
      </div>
    </section>
  );
}

export default Forgot;
