export default function ConfirmEmailForm(props) {

  const {errorMessage, fields, handleFieldChange, handleConfirm} = props;

  return (
    <>
      {errorMessage && (
        <div className="form__row">
          <span className="error">{errorMessage}</span>
        </div>
      )}
      <div className="form__row">
        Kérjük erősítse meg az email címét a kiküldött emailben található link
        megnyitásával, vagy az emailben található kód megadásával.
      </div>
      <div className="form__row">
        <input
          id="email"
          placeholder="E-mail cím"
          onChange={handleFieldChange}
          required
          value={fields.email}
        />
      </div>
      <div className="form__row">
        <input
          id="code"
          placeholder="Kód"
          onChange={handleFieldChange}
          value={fields.code}
        />
      </div>
      <div className="form__row">
        <button className="btn" onClick={handleConfirm}>
          Email cím megerősítése
        </button>
      </div>
    </>);
};
