import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';

import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper'

import { useFormFields } from '../libs/hooks';
import { secureFetch, round, renderPrice } from '../libs/utils'
import userService from '../services/user';
import subscriptionService from '../services/subscription';
import { CartContext } from '../reducers/cartReducer';

function Cart() {
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [stepper, setStepper] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { state, dispatch } = useContext(CartContext);

  useEffect(() => {
    if(!success) {
      setStepper(new Stepper(document.querySelector('#stepper'), {
        linear: true,
        animation: true
      }));
    }
  }, [success]);

  const defaultBillingAddress = state && state.billingAddress
   ? state.billingAddress
   : {
    name: "",
    zipCode: "",
    city: "",
    address: "",
    emailAddress: "",
    vatNumber: "",
  }

  const [fields, handleFieldChange, setAll] =
    useFormFields(defaultBillingAddress);

  const fetchData = useCallback(async () => {
    await secureFetch(
      () => userService.getBillingAddress(),
      (response) => setAll(response.billingAddress),
      setErrorMessage,
      'Nem sikerült lekérdezni a számlázási címet'
    );
  }, [setAll]);

  const submitOrder = async (evt) => {
    if (submitDisabled) {
      return false;
    }

    setSubmitDisabled(true);

    await secureFetch(
      () => subscriptionService.createOrder(state),
      (response) => {
        dispatch({ type: 'finish'});
        setSuccess(true);
      },
      setErrorMessage,
      'Nem sikerült elküldeni a rendelést'
    );

    setSubmitDisabled(false);
  };

  const deleteItem = (item) => {
    dispatch({ type: 'delete', payload: item});
  };

  const validateCart = async (evt) => {
    await secureFetch(
      () => subscriptionService.validateOrder(state),
      () => next(),
      setErrorMessage,
      'Érvénytelen rendelés'
    );
  }

  const validateBillingAddress = async (evt) => {

    setErrorMessage(null);

    if (!fields.name) {
      setErrorMessage('Kérjük, add meg a nevet, amire kiállíthatjuk a számlát!');
      return;
    }

    if (!fields.zipCode || !fields.city || !fields.address) {
      setErrorMessage('Kérjük, add meg a cím minden elemét!');
      return;
    }

    if (!fields.emailAddress) {
      setErrorMessage('Kérjük, add meg az e-mail címet, erre a címre küldjük ki a számlát!');
      return;
    }

    await secureFetch(
      () => subscriptionService.validateBillingAddress(fields),
      () => next(),
      setErrorMessage,
      'Érvénytelen rendelés'
    );
  }

  const next = (evt) => {
    dispatch({ type: 'setBillingAddress', payload: fields});
    stepper.next();
  }

  const previous = (evt) => {
    dispatch({ type: 'setBillingAddress', payload: fields});
    stepper.previous();
  }

  const getGrossPrice = (item) => {
    return item.netItemPrice * (1 + item.vatPercentage);
  }

  useEffect(() => {
    if(!state || !state.billingAddress) {
      fetchData();
    }
  }, [state, fetchData]);

  return (
    <main className="cart">
      {success && (
        <section>
          <div className="container">
            <div className="success">
              <h4>
                Rögzítettük rendelésed.
              </h4>
              <div>
                A díjbekérőt a szamlazz.hu rendszere hamarosan elküldi a
                rendelés során megadott email címre. A megrendelt tételek
                a számla befizetését követően kerülnek jóváírásra számodra.
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="btn-container">
                  <Link to="/dashboard">
                    <button className="btn">Befejezés</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {!success && (
      <section>
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Kosár</h1>
            </div>
          </div>
          {errorMessage && (
            <div className="row">
              <div className="col">
                <span className="error">{errorMessage}</span>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col">
              <div id="stepper" className="bs-stepper">
                <div className="bs-stepper-header">
                  <div className="step" data-target="#test-l-1">
                    <button className="step-trigger">
                      <span className="bs-stepper-circle">1</span>
                      <span className="bs-stepper-label">Tételek</span>
                    </button>
                  </div>
                  <div className="line"></div>
                  <div className="step" data-target="#test-l-2">
                    <button className="step-trigger">
                      <span className="bs-stepper-circle">2</span>
                      <span className="bs-stepper-label">
                        Számlázási adatok
                      </span>
                    </button>
                  </div>
                  <div className="line"></div>
                  <div className="step" data-target="#test-l-3">
                    <button className="step-trigger">
                      <span className="bs-stepper-circle">3</span>
                      <span className="bs-stepper-label">
                        Rendelés véglegesítése
                      </span>
                    </button>
                  </div>
                </div>
                <div className="bs-stepper-content">
                    <div id="test-l-1" className="content">
                      <div className="form-group">
                        {state.items.length > 0 && (
                        <table className="table cart-table">
                          <thead>
                            <tr>
                              <th>Tétel</th>
                              <th>Nettó ár</th>
                              <th>Bruttó ár</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {state.items.map(item => (
                              <tr>
                                <td>{item.product.name}</td>
                                <td>{renderPrice(item.netItemPrice)}</td>
                                <td>
                                  {renderPrice(round(getGrossPrice(item), 2))}
                                </td>
                                <td>
                                  <button className="btn" onClick={(evt) =>
                                    {
                                      deleteItem(item);
                                    }}>
                                    Törlés
                                  </button>
                                </td>
                              </tr>
                            ))}
                            <tr className="summary">
                                <td>Összesen</td>
                                <td>
                                  {renderPrice(state.items.reduce((sum, item) =>
                                      sum + item.netItemPrice, 0)
                                  )}
                                </td>
                                <td>
                                  {renderPrice(state.items.reduce((sum, item) =>
                                      sum + round(getGrossPrice(item), 2), 0)
                                  )}
                                </td>
                                <td>
                                </td>
                              </tr>
                          </tbody>
                        </table>
                        )}
                        {state.items.length === 0 && (
                          <h3>Üres a kosár</h3>
                        )}
                      </div>
                      <div className="btn-container">
                        <button
                          className="btn"
                          onClick={validateCart}
                          disabled={state.items.length === 0}>
                          Tovább
                        </button>
                      </div>
                    </div>
                    <div id="test-l-2" className="content">
                      <div className="form-group billing">
                      <input
                        id="name"
                        type="text"
                        placeholder="Cégnév / Név"
                        onChange={handleFieldChange}
                        value={fields.name} />
                      <input
                        id="zipCode"
                        placeholder="Irányítószám"
                        onChange={handleFieldChange}
                        value={fields.zipCode} />
                      <input
                        id="city"
                        type="text"
                        placeholder="Település"
                        onChange={handleFieldChange}
                        value={fields.city}/>
                      <input
                        id="address"
                        type="text"
                        placeholder="Cím"
                        onChange={handleFieldChange}
                        value={fields.address}/>
                      <input
                        id="emailAddress"
                        type="email"
                        placeholder="Email cím"
                        onChange={handleFieldChange}
                        value={fields.emailAddress}/>
                      <input
                        id="vatNumber"
                        placeholder="Adószám"
                        onChange={handleFieldChange}
                        value={fields.vatNumber} />
                      </div>
                      <div className="btn-container">
                        <button className="btn" onClick={previous}>
                          Vissza
                        </button>
                        <button className="btn"
                          onClick={validateBillingAddress}>
                          Tovább
                        </button>
                      </div>
                    </div>
                    <div id="test-l-3" className="content">
                      <div className="form-group">
                        <h3>Rendelés véglegesítése</h3>
                      </div>
                      <div className="form-group">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Tétel</th>
                              <th>Nettó ár</th>
                              <th>Bruttó ár</th>
                            </tr>
                          </thead>
                          <tbody>
                            {state.items.map(item => (
                              <tr>
                                <td>{item.product.name}</td>
                                <td>{renderPrice(item.netItemPrice)}</td>
                                <td>
                                  {renderPrice(round(getGrossPrice(item), 2))}
                                </td>
                              </tr>
                            ))}
                            <tr className="summary">
                                <td>Összesen</td>
                                <td>
                                  {renderPrice(state.items.reduce((sum, item) =>
                                      sum + item.netItemPrice, 0)
                                  )}
                                </td>
                                <td>
                                  {renderPrice(state.items.reduce((sum, item) =>
                                      sum + round(getGrossPrice(item), 2), 0)
                                  )}
                                </td>
                              </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="form-group">
                        {state.billingAddress && (
                          <div className="finalization">
                            <div>
                              <h5>Cégnév / Név: </h5>
                              <p>{state.billingAddress.name}</p>
                            </div>
                            <div>
                              <h5>Adószám: </h5>
                              <p>{state.billingAddress.vatNumber}</p>
                            </div>
                            <div>
                              <h5>E-mail cím: </h5>
                              <p>{state.billingAddress.emailAddress}</p>
                            </div>
                            <div>
                              <h5>Számlázási cím: </h5>
                              <p>
                                {state.billingAddress.zipCode}{` `}
                                {state.billingAddress.city}{` `}
                                {state.billingAddress.address}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="btn-container">
                        <button className="btn" onClick={previous}>
                          Vissza
                        </button>
                        <button
                          className="btn"
                          onClick={submitOrder}
                          disabled={submitDisabled}
                        >
                          Elküld
                        </button>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      )}
    </main>
  );
}

export default Cart;
