import { createContext } from 'react';

export const cartState =
  JSON.parse(localStorage.getItem('cart')) ??
  {
    items: [],
  };

export const cartReducer = (state, action) => {

  const save = (order) => {
    localStorage.setItem('cart', JSON.stringify(order));
  }

  switch (action.type) {
    case "add": {
      const newState = {
          ...state,
          items: state.items.concat([action.payload])
        };
        save(newState);
        return newState;
      }
    case "delete": {
        const items = state.items.filter(i => i !== action.payload);
        if (items.length === 0) {
          localStorage.removeItem('cart');
          return {
            items: []
          };
        }
        const newState =  {
          ...state,
          items
        };
        save(newState);
        return newState;
      }
    case "setBillingAddress":
      const newState =  {
        ...state,
        billingAddress: action.payload
      };
      save(newState);
      return newState;
    case "finish":
      localStorage.removeItem('cart');
      return {
        items: []
      };
    default:
      return state;
  }
};

export const CartContext = createContext();

