import ReactTooltip from 'react-tooltip';

const PasswordRequirements = (props) => (
  <ReactTooltip
    id="password"
    {...props}
  >
    <div>
      <div>Legalább 8 karakter hosszú</div>
      <div>Legalább 1 kis és nagybetűt tartalmaz</div>
      <div>Legalább 1 számot tartalmaz</div>
    </div>
  </ReactTooltip>
);

export default PasswordRequirements;
