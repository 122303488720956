import React, { useState } from 'react';

import { useFormFields } from "../../libs/hooks";
import userService from "../../services/user";
import { secureFetch } from '../../libs/utils';
import PasswordRequirements from '../../components/popup/PasswordRequirements';

function ChangePassword() {

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [fields, handleFieldChange, setAll] = useFormFields({
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
  });

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setSuccessMessage("");

    await secureFetch(
      () => userService.changePassword(fields),
      () => {
        setSuccessMessage('Jelszó módosítva');
        setAll({
          oldPassword: "",
          newPassword: "",
          newPasswordRepeat: ""
        })
      },
      setErrorMessage,
      'Hiba a jelszómódosítás során'
    );
  };

  return (
    <>
      <div className="dashboard__inner--row">
        <h3>Jelszó módosítása</h3>
        {errorMessage && (
          <div className="dashboard__inner--row">
            <span className="error">{errorMessage}</span>
          </div>
        )}
        {successMessage && (
          <div className="dashboard__inner--row">
            <span className="success">{successMessage}</span>
          </div>
        )}
        <input
          id="oldPassword"
          type="password"
          placeholder="Régi jelszó"
          value={fields.oldPassword}
          onChange={handleFieldChange}
        />
        <PasswordRequirements/>
        <input
          id="newPassword"
          type="password"
          placeholder="Új jelszó"
          value={fields.newPassword}
          onChange={handleFieldChange}
          data-tip
          data-for="password"
        />
        <input
          id="newPasswordRepeat"
          type="password"
          placeholder="Új jelszó ismét"
          onChange={handleFieldChange}
          value={fields.newPasswordRepeat}
          />
        <button
          className="btn"
          onClick={handlePasswordChange}>Jelszó módosítása</button>
      </div>
    </>
  );
}

export default ChangePassword;
