import React from 'react';
import { Accordion } from 'react-bootstrap';

import authService from "../../services/auth";

import ChangePassword from './ChangePassword';
import UpdateBillingAddress from './UpdateBillingAddress';
import DeleteAccount from './DeleteAccount';

function Profile() {
  const user = authService.getCurrentUser();

  return (
    <Accordion>
      <div className="dashboard__inner">
        <div className="dashboard__inner--row">
          <h2>Fiók</h2>
        </div>
        {user && (
          <div className="dashboard__inner--row">
            <p>{user.name}</p>
            <p>{user.email}</p>
          </div>
        )}
        <ChangePassword/>
        <UpdateBillingAddress/>
        <DeleteAccount/>
      </div>
    </Accordion>
  )
}

export default Profile;
