import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactTooltip from "react-tooltip";

import { useDidUpdateEffect } from '../../libs/hooks';
import { secureFetch } from '../../libs/utils';

import authService from '../../services/auth';
import userService from '../../services/user';

import logo from '../../images/IM_Logo.png';

import ErrorModal from '../../components/modal/ErrorModal';
import SwitchModeModal from '../../components/modal/SwitchModeModal';

const LoggedInHeader = () => {

  const [errorMessage, setErrorMessage] = useState("");
  const [isDemo, setIsDemo] = useState(authService.getCurrentUser().isDemo);
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const logOut = () => {
    authService.logout();
    history.push('/');
  }

  const handleChangeDemo = async (evt) => {
    const newValue = !evt.target.checked;

    await secureFetch(
      () => userService.setMode(newValue),
      () => {
        authService.updateUser({ isDemo: newValue });
        setIsDemo(newValue);
      },
      setErrorMessage,
      'Nem sikerült módosítani a működési módot'
    );
  }

  useDidUpdateEffect(() => setShowModal(true), [isDemo]);

  return (
    <header>
      <div className="container">
        <nav class="navbar navbar-expand-lg navbar-light">
          <Link to="/">
            <img src={logo} className="logo" alt="logo" />
          </Link>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto align-items-center">
              <div className="demo-toggler">
                <p>Teszt</p>
                <div>
                  <input
                    className="apple-switch"
                    type="checkbox"
                    id="allowSignatureWithMouse"
                    checked={!isDemo}
                    onChange={handleChangeDemo}
                    data-tip
                    data-for='switch'
                  />
                </div>
                <p>Érvényes</p>
                <ReactTooltip id='switch'>
                  <span>
                    Váltás ingyenes tesztaláírás
                    és jogilag érvényes aláírás között
                  </span>
                </ReactTooltip>
              </div>
              <Link to="/dashboard">
                <button
                  className="btn"
                  data-tip
                  data-for='dashBoard'
                >
                  <i className="fa fa-address-card-o" aria-hidden="true"></i>
                </button>
              </Link>
              <ReactTooltip id='dashBoard'>
                <span>Felhasználói fiók</span>
              </ReactTooltip>
              <button
                className="btn"
                onClick={() => logOut()}
                data-tip
                data-for='logOut'
              >
                <i className="fa fa-sign-out" aria-hidden="true"></i>
              </button>
              <ReactTooltip id='logOut'>
                <span>Kilépés</span>
              </ReactTooltip>
            </ul>
          </div>
          <SwitchModeModal
            isDemo={isDemo}
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <ErrorModal
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        </nav>
      </div>
    </header>
  );
}

export default LoggedInHeader;
