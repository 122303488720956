

import { Link } from "react-router-dom";

const ResetPasswordSuccess = () => {
  return (
    <>
      <div className="form__row">
        Sikeresen módosítottad a jelszavadat, most már bejelentkezhetsz.
      </div>
      <div className="form__row">
        <Link to="/login">
          <button className="btn">
            Bejelentkezés
          </button>
        </Link>
      </div>
    </>
  );
};

export default ResetPasswordSuccess;
