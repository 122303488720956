import CookieConsent from "react-cookie-consent";

const Footer = () => {
  return (
    <footer>
      <CookieConsent
         disableStyles={true}
         containerClasses="cookie-consent"
         contentClasses="cookie-consent-content"
         buttonClasses="btn cookie-consent-button"
         buttonText="Elfogadom">
          A weboldal cookie-kat használ a felhasználói élmény növeléséhez.
          {' '}
          <a href="/files/cookie_policy.pdf">
            Cookie policy
          </a>
      </CookieConsent>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-md-12">
            <div className="footer-links">
              <a
                href="/files/felhasznalasi_feltetelek.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Általános Szerződési Feltételek
              </a>
              <a
                href="/files/adatkezelesi_tajekoztato.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Adatkezelési tájékoztató
              </a>
              <a
                href="/files/adatfeldolgozoi_nyilatkozat.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Adatfeldolgozási tájékoztató
              </a>
              <a
                href="/files/cookie_policy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Cookie Policy
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-12">
            <div className="footer-text">
              <p>© 2021 SignoWise <span>by <a href="https://cursorinsight.com" target="_blank" rel="noreferrer">Cursor Insight Ltd.</a></span></p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
