
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Accordion } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { usePageVisibility } from 'react-page-visibility';

import documentService from "../../services/documents";
import authService from "../../services/auth";
import ErrorModal from "../../components/modal/ErrorModal";

import { getDate, getMeta, secureFetch, openPopup } from '../../libs/utils';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const getErrorMessage = (code) => {
  switch(code) {
    case 'file-invalid-type':
      return 'Csak PDF fájlt lehet feltölteni';
    default:
      return 'Nem sikerült feltölteni a fájlt';
  }
}

function UnsignedDocuments() {
  const [documents, setDocuments] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const refresh = useCallback(async() => {
    await secureFetch(
      () => documentService.getUnsignedDocuments(),
      (result) => setDocuments(result.sessions),
      setErrorMessage,
      'Nem sikerült lekérdezni a dokumentumokat'
    );
  }, []);

  const deleteDocument = async(workflowId) => {
    await secureFetch(
      () => documentService.terminateDocument(workflowId),
      () => refresh(),
      setErrorMessage,
      'Nem sikerült törölni a dokumentumot'
    );
  }

  const isVisible = usePageVisibility();
  useEffect(() => {
    if (isVisible) {
      refresh();
    }
  }, [refresh, isVisible]);

  const user = authService.getCurrentUser();
  const userName = user ? user.name : "";

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()

      reader.onabort = () => setErrorMessage('Nem sikerült a fájlt beolvasni');
      reader.onError = () => setErrorMessage('Nem sikerült a fájlt beolvasni');
      reader.onload = async () => {
        const binaryStr = reader.result;
        await secureFetch(
          () => documentService.createDocument(
            file.path,
            userName,
            binaryStr.substring(binaryStr.indexOf(',') + 1)),
          () => { refresh() },
          setErrorMessage,
          'Nem sikerült feldtölteni a dokumentumot'
        );
      }
      reader.readAsDataURL(file);
    }, []);
  }, [setErrorMessage, refresh, userName]);

  const startDocument = async (document) =>
  {
    const url = await secureFetch(
      () => documentService.initializeDocument(document.workflowId),
      () => {
        return `/sign/index.html?workflowId=${document.workflowId}`
      },
      setErrorMessage,
      'Nem sikerült megnyitni a dokumentumot aláírásra'
    );
    return url;
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({
    onDrop,
    accept: 'application/pdf'
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path}{`: `}{errors.map(e => getErrorMessage(e.code))}
     </li>
  ));

  const getStatus = (document) => {
    return document.signatureFields > document.unsignedSignatureFields
      ? 'Részben aláírva'
      : 'Nincs aláírva';
  }

  return (
    <Accordion>
      <div className="dashboard__inner">
        <div className="dashboard__inner--row docs">
          <h2>Aláírandó dokumentumok</h2>
        </div>
        <div className="dashboard__inner--row docs">
          <ErrorModal
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
          <div className="row">
            <div className="uploader" {...getRootProps({style})}>
              <input {...getInputProps()} />
              <p>
                {isDragAccept && "Minden fájl megfelelő"}
                {isDragReject && "Csak PDF fájlokat lehet feltölteni"}
                {
                  !isDragAccept && !isDragReject && "Aláírandó PDF dokumentum feltöltéséhez kattints ide, vagy akár drag-and-drop húzd erre a mezőre!"
                }
              </p>
            </div>
            <ul className="error">{fileRejectionItems}</ul>
          </div>

          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <button className="btn" onClick={refresh}>
                <i className="fa fa-refresh" aria-hidden="true"></i> Frissít
              </button>
            </div>
          </div>

          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  <th>Fájlnév</th>
                  <th>Feltöltve</th>
                  <th>Státusz</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {documents.map((document) => (
                  <tr>
                    <td>{getMeta(document, 'fileName')}</td>
                    <td>{getDate(document.createdAt)}</td>
                    <td>{getStatus(document)}</td>
                    <td>
                      <button
                        className="btn"
                         onClick={() => openPopup(startDocument(document))}>
                        Aláír
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn"
                        onClick={() => deleteDocument(document.workflowId)}>
                        Töröl
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Accordion>
  );
}

export default UnsignedDocuments;
