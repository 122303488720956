export function downloadFile(fileName, b64file) {
    if (window.navigator.msSaveBlob) {
      const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i += 1) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      };

      window.navigator.msSaveBlob(
        b64toBlob(b64file, 'application/pdf'),
        fileName,
      );
    } else {
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        `data:application/pdf;base64,${b64file}`,
      );
      element.setAttribute('download', `${fileName}`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    return true;
  }