import logo from '../../images/logo.svg';

const DemoHeader = () => {
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <a href="https://signowise.hu">
              <img src={logo} className="logo" alt="logo" />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default DemoHeader;
