import axios from "axios";

import { BASE_URL, getAxiosConfig } from "./common.js"

const API_URL = `${BASE_URL}/authenticate/`

const register = (fields) => {
  return axios
    .post(
      API_URL + "register",
      fields,
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
}

const confirmEmail = (fields) => {
  return axios
    .post(
      API_URL + "confirmEmail",
      fields,
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
}

const login = (fields) => {
  return axios
    .post(
      API_URL + "login",
      fields,
      getAxiosConfig())
    .then((response) => {
      if (response.data.user && response.data.user.token) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("token", response.data.user.token);
      }
      return response.data;
    });
};

const forgotPassword = (fields) => {
  return axios
    .post(
      API_URL + "forgotPassword",
      fields,
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const resetPassword = (fields) => {
  return axios
    .post(
      API_URL + "resetPassword",
      fields,
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const setEmailAddress = (email) => {
  localStorage.setItem("email", email);
};

const getEmailAddress = () => {
  return localStorage.getItem("email");
};

const updateUser = (props) => {
  const currentUser = getCurrentUser()
  const user = {...currentUser, ...props};
  localStorage.setItem("user", JSON.stringify(user));
}

const services = {
  register,
  confirmEmail,
  login,
  logout,
  getCurrentUser,
  forgotPassword,
  resetPassword,
  setEmailAddress,
  getEmailAddress,
  updateUser,
};

export default services;
