import { Link } from 'react-router-dom';
import logo from '../images/logo-black.svg';

function ShouldBeLoggedIn() {
  return (
    <section className="login">
      <div className="container">
        <form className="form">
          <div className="form__logo">
            <img src={logo} className="logo" alt="logo" />
          </div>
          <div className="form__row">
            Kérjük <Link to="login">jelentkezz be</Link> az oldal megtekitéséhez.
          </div>
        </form>
      </div>
    </section>
  );
}

export default ShouldBeLoggedIn;
