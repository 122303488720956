import axios from "axios";
import { BASE_URL, getAxiosConfig } from "./common.js"

const API_URL = `${BASE_URL}/demo/`

const startSampleDocument = (sampleDocumentPath) => {
  return axios.post(
      API_URL + "startSampleDocument",
      { sampleDocumentPath },
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const services = {
  startSampleDocument
}

export default services;
