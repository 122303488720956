import { useState, useRef, useEffect, useCallback } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function(event) {
      let key = null;
      let value = null;

      switch(event.target.type) {
        case 'radio':
          key = event.target.name;
          value = event.target.value === 'true'
          break;
        case 'checkbox':
          key = event.target.id;
          value = event.target.checked
          break;
        case 'number':
          key = event.target.id;
          value = event.target.valueAsNumber;
          break;
        default:
          key = event.target.id;
          value = event.target.value;
          break;
      }

      setValues({
        ...fields,
        [key]: value
      });
    },
    useCallback((values) => {
      setValues({
        ...values
      });
    }, [])
  ];
}

export function useDidMountEffect(func, deps) {
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      func();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useDidUpdateEffect(func, deps) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
