import { Link } from 'react-router-dom';

export default function ConfirmEmailSuccess() {
  return (
    <>
      <div className="form__row">
        Sikeresen megerősítette az e-mail címét, most már bejelentkezhet.
      </div>
      <div className="form__row">
        <Link to="/login">
          <button className="btn">
            Bejelentkezés
          </button>
        </Link>
      </div>
    </>
  );
}
