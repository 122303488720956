import { useState } from 'react';
import '../App.scss';
import DemoHeader from '../sections/header/DemoHeader';
import Footer from '../sections/footer/Footer';

import tabletImg1 from '../images/tablet-doc-2.png';
import tabletImg2 from '../images/tablet-doc-4.png';

import demoService from '../services/demo';
import { openPopup, secureFetch } from '../libs/utils'

function Demo() {
  const [errorMessage, setErrorMessage] = useState("");

  const startSampleDocument = async (sampleDocumentPath) => {
    const url = await secureFetch(
      () => demoService.startSampleDocument(sampleDocumentPath),
      (response) => {
        const url = `/sign/index.html?workflowId=${response.workflowId}` +
          `&token=${response.token}`;
        return url;
      },
      setErrorMessage,
      'Nem sikerült betölteni a dokumentumot'
    );
    return url;
  }

  return (
    <>
      <DemoHeader />
      <section className="demo">
        <div className="container">
          <div className="row mb-5 pb-5">
            <div className="col-lg-12 demo__title">
              <h2>SignoWise biometrikus e-aláírás mintadokumentum demo</h2>
            </div>
            {errorMessage && (
                <div className="col-lg-12 demo__error">
                  {errorMessage}
                </div>
              )}
            <div className="col-lg-6 demo__item">
              <button onClick={() =>
                  openPopup(startSampleDocument('medical_record'))}
              >
                <p>Ambuláns vizsgálati lap</p>
                <img
                  src={tabletImg1}
                  alt="advantageImg"
                  className="advantage-img"
                />
              </button>
            </div>
            <div className="col-lg-6 demo__item">
              <button onClick={() =>
                openPopup(startSampleDocument('rent'))}
              >
                <p>Gépbérleti szerződés minta</p>
                <img
                  src={tabletImg2}
                  alt="advantageImg"
                  className="advantage-img"
                />
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Demo;
