import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import Settings from "./sections/Settings";
import SignedDocuments from "./sections/SignedDocuments";
import UnsignedDocuments from "./sections/UnsignedDocuments";
import Profile from "./sections/Profile";
import Helper from "./sections/Helper";
import authService from "../services/auth";

function Dashboard() {
  const currentUser = authService.getCurrentUser();
  const defaultActiveKey = currentUser.disableHelp ? "unsigned" : "helper";
  const [key, setKey] = useState(defaultActiveKey);

  return (
    <main className="dashboard">
      <section>
        <Tabs
          activeKey={key}
          onSelect={(k) => setKey(k)}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <Tab title="Aláírómappák">
          </Tab>
          <Tab eventKey="unsigned" title="Aláírandók">
            <UnsignedDocuments/>
          </Tab>
          <Tab eventKey="signed" title="Aláírt dokumentumok">
            <SignedDocuments/>
          </Tab>
          <Tab title="Menü">
          </Tab>
          <Tab eventKey="settings" title="Beállítások">
            <Settings/>
          </Tab>
          <Tab eventKey="user" title="Felhasználói adatok">
            <Profile/>
          </Tab>
          <Tab eventKey="helper" title="Segítség">
            <Helper/>
          </Tab>
        </Tabs>
      </section>
    </main>
  );
}

export default Dashboard;
